/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.antSteps {
  :global(.ant-steps-icon) {
    top: -11px;
  }

  :global(.ant-steps-item-icon) {
    margin-right: 132px !important;
  }

  :global(.ant-steps-item-tail::after) {
    margin-top: 8px;
    height: 96% !important;
    margin-left: 45px !important;
    background-color: $brand !important;
  }

  :global(.ant-steps-item-title) {
    margin-bottom: 23px;
    font-size: 40px !important;
    font-family: Poppins-Bold, sans-serif !important;
  }

  :global(.ant-steps-item-description) {
    padding-bottom: 115px !important;
    font-size: 16px !important;
    font-family: Poppins-Regular, sans-serif !important;
    color: $primary-text !important;
    line-height: 22px;
    opacity: .5;
  }
}

.defaultDot {
  display: inline-block;
  white-space: nowrap;
  padding: 0 10px;
  border-radius: 38px;
  color: $brand;
  width: 75.5px;
  height: 36px;
  font-size: 14px;
  font-family: Poppins-Regular, sans-serif;
  line-height: 34px;
  text-align: center;
  border: 1px solid $brand;
  box-sizing: border-box;
}

.stepModuleWrap {
  background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
  background-repeat: no-repeat;
  background-position: -69px calc(100% + 44.76px), calc(100% + 223px) -362px;
  background-size: auto 424px;

  .stepModuleContainer {
    margin: auto;
    padding: 100px 0 0;
    max-width: $divide-1080;

    .stepModuleImgWrap {
      position: relative;
      padding-right: 125px;

      .stepModuleContentWrap {
        position: sticky;
        margin-bottom: 215px;
        top: 100px;
        z-index: 10;
      }

      .stepModuleTitle {
        color: $primary-text;
        font-family: Poppins-Bold, sans-serif;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
  
        .purple {
          font-size: 44px;
          color: $brand;
          height: 56px;
          line-height: 56px;
          vertical-align: bottom;
        }
      }

      .stepModuleLink {
        margin-top: 22px;
        height: 54px;
        line-height: 54px;
        padding: 0 32px;
        border-radius: 42px;
      }

    }

    .stepModuleImgBg {
      position: sticky;
      top: 310px;
      width: 190px;
      height: auto;
      z-index: 0;
    }

  }
}
@media screen and (max-width: $divide-1080) {

  .antSteps {
    :global(.ant-steps-item-tail::after) {
      margin-top: 8px;
    }
  }
  
  .stepModuleWrap {
    background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
    background-repeat: no-repeat;
    background-position: -69px calc(100% + 44.76px), calc(100% + 223px) -362px;
    background-size: auto 424px;
  
    .stepModuleContainer {
      padding: 92px 0 0;
      max-width: 100%;
  
      .stepModuleImgWrap {
        padding-right: 25px;
        height: 100%;
  
        .stepModuleTitle {
          color: $primary-text;
          font-family: Poppins-Bold, sans-serif;
          font-size: 44px;
          font-style: normal;
          font-weight: 700;
          line-height: 56px;
  
          .purple {
            font-size: 44px;
            color: $brand;
            height: 56px;
            line-height: 56px;
            vertical-align: bottom;
          }
        }
  
        .stepModuleLink {
          margin-top: 22px;
          height: 48px;
          line-height: 48px;
          padding: 0 26px;
        }
  
      }
  
    }
  }
}
@media screen and (max-width: $divide-991) {
  .antSteps {
    :global(.ant-steps-icon) {
      top: -11px;
    }
  
    :global(.ant-steps-item-icon) {
      margin-right: 65px !important;
    }
  
    :global(.ant-steps-item-tail::after) {
      margin-top: -2px;
      height: 101% !important;
      margin-left: 35px !important;
      background-color: $brand !important;
    }
  
    :global(.ant-steps-item-title) {
      margin-bottom: 17px;
      font-size: 28px !important;
    }
  
    :global(.ant-steps-item-description) {
      padding-bottom: 82px !important;
      font-size: 11px !important;
      line-height: 16px;
    }
  
  }
  
  .defaultDot {
    padding: 0 10px;
    border-radius: 13px;
    width: 53px;
    height: 25px;
    font-size: 10px;
    line-height: 25px;
  }
  
  .stepModuleWrap {
    background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
    background-repeat: no-repeat;
    background-position: -15px calc(100% + 44.76px), calc(100% + 27px) -158px;
    background-size: auto 220px;
  
    .stepModuleContainer {
      margin: auto;
      padding: 71px 0 0;
  
      .stepModuleImgWrap {
        position: relative;
        padding-right: 70px;

        .stepModuleContentWrap {
          margin-bottom: 136px;
        }
  
        .stepModuleTitle {
          font-size: 34px;
          line-height: 46px;
  
          .purple {
            font-size: 34px;
            height: 46px;
            line-height: 46px;
          }
        }
  
        .stepModuleLink {
          margin-top: 22px;
          height: 44px;
          line-height: 44px;
          padding: 0 24px;
        }

      }
  
    }
  }
}

@media screen and (max-width: $divide-767) {
  .antSteps {
    :global(.ant-steps-icon) {
      top: -11px;
    }
  
    :global(.ant-steps-item-icon) {
      margin-right: 102px !important;
    }
  
    :global(.ant-steps-item-description) {
      font-size: 13px !important;
      padding-bottom: 49px !important;
    }
  
    :global(.ant-steps-item-tail::after) {
      margin-top: 3px;
      height: 98% !important;
      margin-left: 40px !important;
      background-color: $brand !important;
    }
  
    :global(.ant-steps-item-title) {
      margin-bottom: 20px;
      font-size: 22px !important;
    }
  }

  .defaultDot {
    border-radius: 38px;
    color: $brand;
    width: 62px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
  }

  .stepModuleWrap {
    background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
    background-repeat: no-repeat;
    background-position: -97px -22px, calc(100% + 28px) -362px;
    background-size: auto 225px;

    .stepModuleContainer {
      padding: 36px 0;
      max-width: 100%;

      .stepModuleImgWrap {
        padding-right: 0;
        padding-bottom: 160px;

        .stepModuleContentWrap {
          margin-bottom: 0;
          position: static;
        }

        .stepModuleTitle {
          color: $primary-text;
          font-family: Poppins-Bold, sans-serif;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 38px;
  
          .purple {
            font-size: 28px;
            height: 38px;
            line-height: 38px;
          }
        }

        .stepModuleLink {
          margin-top: 22px;
          font-size: 13px;
          height: 36.75px;
          line-height: 36.75px;
          padding: 0 21.43px;
          border-radius: 28.13px;
        }

        .stepModuleImgBg {
          position: absolute;
          top: auto;
          left: auto;
          right: 23px;
          bottom: 15px;
          width: 137px;
          height: auto;
        }
      }
    }
  }
}