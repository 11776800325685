/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.brandModuleWrap {
  display: flex;
  align-items: center;
  background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
  background-repeat: no-repeat;
  background-position: -69px calc(100% + 392px), calc(100% + 58px) -194px;
  background-size: auto 424px;
  height: 100vh;

  .brandModuleContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: $divide-1080;

    .brandModulefakeBtn {
      color: #7D48E8;
      font-family: Poppins-Regular, sans-serif;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
      height: 44px;
      line-height: 44px;
      padding: 0 21.33px;
      gap: 57.59px;
      border-radius: 38px;
      border: 1px solid #D8C8F8;
    }

    .brandModuleHeader {
      position: absolute;
      left: -125px;
      top: 132px;
      width: 125px;
      height: auto;
    }

    .brandModuleFooter {
      position: absolute;
      right: -132px;
      bottom: 0;
      width: 166px;
      height: 161px;
      overflow: hidden;
    }

    .brandModuleTitle {
      color: $primary-text;
      text-align: center;
      font-family: Poppins-Bold, sans-serif;
      font-size: 98px;
      font-style: normal;
      font-weight: 700;
      line-height: 98px;

      .purple {
        color: $brand;
      }
    }
  
    .brandModuleDesc {
      margin: 45px 0;
      color: $primary-text;
      text-align: center;
      font-family: Poppins-Regular, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 28px */
    }

    .brandModuleBtns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;

      .linkBtn {
        padding: 0 32px;
        height: 54px;
        line-height: 54px;
      }
    }
  }

  @media screen and (max-width: $divide-1080) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;

    .brandModuleContainer {
      max-width: $divide-1080;
      padding: 260px 20px 128px;

      .brandModuleHeader {
        top: 168px;
        left: 22px;
        width: 166px;
      }

      .brandModuleFooter {
        right: 0;
      }

      .brandModuleTitle {
        margin-top: 78px;
        font-size: 78px;
        line-height: 78px;
      }
    
      .brandModuleDesc {
        margin: 45px 0;
        font-size: 22px;
        font-weight: 400;
        line-height: 100%;
      }
    }
  }

  @media screen and (max-width: $divide-991) {
    background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
    background-position: -15px 175px, -15px calc(100% + 12px), calc(100% + 34px) -26px;
    background-size: auto 220px;

    .brandModuleContainer {
      max-width: 100%;
      padding: 260px 20px 128px;

      .brandModuleHeader {
        top: 128px;
        left: 22px;
        width: 126px;
      }

      .brandModuleFooter {
        right: 0;
      }

      .brandModuleTitle {
        margin-top: 46px;
        font-size: 61px;
        line-height: 61px;
      }
    
      .brandModuleDesc {
        margin: 64px 0;
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: $divide-767) {
    background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
    background-position: -15px 175px, calc(100% + 34px) calc(100% - 167px), calc(100% + 34px) -26px;
    background-size: auto 95px;

    .brandModuleContainer {
      padding: 196px 0 182px;

      .brandModulefakeBtn {
        color: #7D48E8;
        font-family: Poppins-Regular, sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        height: 27.46px;
        line-height: 27.46px;
        padding: 0 18.69px;
        border-radius: 23.68px;
      }

      .brandModuleHeader {
        top: 98px;
        left: 0;
        width: 90px;
      }

      .brandModuleFooter {
        bottom: 0;
        width: 117px;
        height: 113px;
      }

      .brandModuleTitle {
        margin-top: 44px;
        font-size: 40px;
        line-height: 40px;
      }
    
      .brandModuleDesc {
        margin: 30px 0;
        font-size: 16px;
        line-height: 100%;
      }
      .brandModuleBtns {
        .linkBtn {
          font-size: 13px;
          padding: 0 22px;
          height: 36px;
          line-height: 36px;
        }
      }
    }

  }
}

