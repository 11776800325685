/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.outroModuleWrap {
  position: relative;
  background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
  background-repeat: no-repeat;
  background-position: -69px -238px, calc(100% + 56px) calc(100% + 140px);
  background-size: auto 424px;
  overflow: hidden;;

  .lottie {
    position: absolute;
    right: 55%;
    bottom: -134px;
    width: 760px;
    height: 540px;
    overflow: hidden;
  }

  .outroModuleContainer {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: $divide-1080;
    padding: 240px 0 280px;

    .outroModuleTitle {
      margin-bottom: 61px;
      color: $primary-text;
      text-align: center;
      font-family: Poppins-Bold, sans-serif;
      font-size: 79px;
      line-height: 79px; /* 80px */

      .purple {
        color: $brand;
      }
    }

    .outroModuleBtns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;

      .linkButton {
        height: 54px;
        font-size: 18px;
        line-height: 54px;
        padding: 0 32px;
        border-radius: 42px;
      }
    }
  }

  @media screen and (max-width: $divide-1080) {
    background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
    background-repeat: no-repeat;
    background-position: -69px -238px, calc(100% + 56px) calc(100% + 140px);
    background-size: auto 424px;
  
    .outroModuleContainer {
      max-width: 100%;
      padding: 220px 0 257px;
  
      .outroModuleTitle {
        margin-bottom: 56px;
        font-size: 69px;
        line-height: 69px;
      }
    }

    .outroModuleBtns {

      .linkButton {
        height: 50px;
        font-size: 16px;
        line-height: 50px;
        padding: 0 28px;
        border-radius: 38px;
      }
    }
  }

  @media screen and (max-width: $divide-991) {
    background-image: url('../../../../assets/images/home/bg_dots.png'), url('../../../../assets/images/home/bg_dots.png');
    background-repeat: no-repeat;
    background-position: -15px -140px, calc(100% + 27px) calc(100% + 34px);
    background-size: auto 220px;

    .lottie {
      left: 0;
      bottom: -10px;
      width: 406px;
      height: 306px;
      overflow: hidden;
    }

    .outroModuleContainer {
      max-width: 100%;
      padding: 170px 22px 199px;

      .outroModuleTitle {
        margin-top: 42px;
        font-size: 49px;
        line-height: 49px;
      }
    
      .outroModuleDesc {
        margin: 64px 0;
        font-size: 28px;
      }

      .outroModuleBtns {
  
        .linkButton {
          height: 44px;
          font-size: 15px;
          line-height: 44px;
          padding: 0 24px;
          border-radius: 34px;
        }
      }
    }
  }

  @media screen and (max-width: $divide-767) {
    background-image: url('../../../../assets/images/home/bg_dots.png');
    background-repeat: no-repeat;
    background-position: calc(100% + 36px) top;
    background-size: auto 225px;

    .lottie {
      left: 50%;
      bottom: -100px;
      width: 406px;
      height: 306px;
      overflow: hidden;
      transform: translateX(-50%);
    }

    .outroModuleContainer {
      padding: 116px 0 204px;

      .outroModuleTitle {
        margin-bottom: 30px;
        font-size: 34px;
        line-height: 34px;
      }

      .linkButton {
        padding: 0 21.43px;
        height: 36.75px;
        font-size: 13px;
        line-height: 36.75px;
        border-radius: 28.13px;
      }
    }
  }
}

